// Toasts

.toast {
  background-color: $toast-background-color;
  border: 0;
  box-shadow: $toast-box-shadow;

  .btn-close {
    width: 1.3em;
  }
}

.toast-header {
  background-color: $toast-header-background-color;
  border-bottom-width: 2px;

  .btn-close {
    margin-right: -0.375rem;
    margin-left: 0.75rem;
  }
}

.parent-toast-relative {
  position: relative;
}

.toast-absolute {
  position: absolute;
}

.toast-fixed {
  position: fixed;
  z-index: $zindex-toast;
}

@each $color, $value in $toasts {
  .toast-#{$color} {
    background-color: map-get($value, background-color);
    color: map-get($value, text-color);
    border-color: map-get($value, border-color);
  }
}
