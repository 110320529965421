@mixin mdb-stepper-theme($theme) {
  $primary: map-get($theme, primary);
  $secondary: map-get($theme, secondary);
  $surface: map-get($theme, surface);
  $onSurface: map-get($theme, onSurface);
  $onPrimary: map-get($theme, onPrimary);
  $onSecondary: map-get($theme, onSecondary);
  $error: map-get($theme, error);
  $onError: map-get($theme, onError);
  $success: map-get($theme, success);
  $onSuccess: map-get($theme, onSuccess);
  $surfaceHover: map-get($theme, surfaceHover);
  $pickerHeader: map-get($theme, pickerHeader);
  $tooltipBackground: map-get($theme, tooltipBackground);
  $dropdownHeader: map-get($theme, dropdownHeader);
  $divider: map-get($theme, divider);

  .stepper:not(.stepper-vertical) {
    .stepper-step {
      &:not(:first-child) .stepper-head {
        &:before {
          background-color: rgba($onSurface, 0.1);
        }
      }
      &:not(:last-child) .stepper-head {
        &:after {
          background-color: rgba($onSurface, 0.1);
        }
      }
    }
  }
  .stepper-vertical {
    .stepper-step {
      &:not(:last-child):after {
        background-color: rgba($onSurface, 0.1);
      }
    }
  }
  .stepper-head {
    &:hover {
      background-color: rgba($tooltipBackground, 0.2);
    }
  }
  .stepper-head-icon {
    color: $onPrimary;
    background-color: $tooltipBackground;
  }
  .stepper-completed .stepper-head-icon {
    background-color: $success;
  }
  .stepper-completed .stepper-head-icon {
    background-color: $tooltipBackground;
  }
  .stepper-active {
    .stepper-head-icon {
      background-color: $primary;
    }
  }
  .stepper-invalid .stepper-head-icon {
    background-color: $danger;
  }
  .stepper-disabled {
    .stepper-head {
      color: lighten($tooltipBackground, 10%);
    }
    .stepper-head-icon {
      background-color: lighten($tooltipBackground, 10%);
    }
  }
  .stepper-mobile-progress {
    background-color: $tooltipBackground;
  }
}
