// Alert

.alert {
  border: 0;
  border-radius: $alert-border-radius;
}

.alert-absolute {
  position: absolute;
}

.alert-fixed {
  position: fixed;
  z-index: $zindex-alert;
}

.parent-alert-relative {
  position: relative;
}

@each $color, $value in $alerts {
  .alert-#{$color} {
    background-color: map-get($value, background-color);
    color: map-get($value, text-color);

    i {
      color: map-get($value, icon-color);
    }

    .alert-link {
      color: map-get($value, text-color);

      &:hover {
        color: shift-color(map-get($value, text-color), 20%);
      }
    }
  }
}
