// Popover

.popover {
  border: 1px solid #f5f5f5;
  box-shadow: $box-shadow-2;

  .popover-arrow {
    display: none;
  }
}

.popover-header {
  background-color: $popover-background-color;
  border-bottom: $popover-border-width solid $popover-border-color;
}
