.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    margin-bottom: 0 !important;    
}

.equal-height {
    display: flex;
    height: 100%;
}

/* if .tree-item is also .hidden, animate */

.tree-item.hidden {
    display: none;
}

.fc-toolbar { text-transform: capitalize; }

.fc-col-header-cell-cushion  { text-transform: capitalize; }

.fc-daygrid-event {
  white-space: normal !important;
  align-items: normal !important;
}