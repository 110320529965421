// range
.range {
  position: relative;

  .thumb {
    position: absolute;
    display: block;
    height: 30px;
    width: 30px;
    top: -35px;
    margin-left: -15px;
    text-align: center;
    border-radius: 50% 50% 50% 0;
    transform: scale(0);
    transform-origin: bottom;
    transition: transform 0.2s ease-in-out;

    &:after {
      position: absolute;
      display: block;
      content: '';
      transform: translateX(-50%);
      width: 100%;
      height: 100%;
      top: 0;
      border-radius: 50% 50% 50% 0;
      transform: rotate(-45deg);
      background: $primary;
      z-index: -1;
    }

    .thumb-value {
      display: block;
      font-size: 12px;
      line-height: 30px;
      color: rgb(255, 255, 255);
      font-weight: 500;
      z-index: 2;
    }

    &.thumb-active {
      transform: scale(1);
    }
  }
}
