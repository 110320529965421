// Navs

//
// Tabs
//

.nav-tabs {
  border-bottom: 0;

  .nav-link {
    border-width: $nav-tabs-link-border-width;
    border-style: solid;
    border-color: transparent;
    border-radius: 0;
    text-transform: uppercase;
    line-height: 1;
    font-weight: $nav-tabs-link-font-weight;
    font-size: $nav-tabs-link-font-size;
    color: $nav-tabs-link-color;
    padding: $nav-tabs-link-padding-top $nav-tabs-link-padding-x $nav-tabs-link-padding-bottom $nav-tabs-link-padding-x;

    &:hover {
      background-color: $nav-tabs-link-hover-bgc;
      border-color: transparent;
    }

    &:focus {
      border-color: transparent;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: $primary;
    border-color: $primary;
  }
}

//
// Pills
//

.nav-pills {
  margin-left: -$nav-pills-margin;

  .nav-link {
    border-radius: $nav-pills-link-border-radius;
    font-size: $nav-pills-link-font-size;
    text-transform: uppercase;
    padding: $nav-pills-link-padding-top $nav-pills-link-padding-x $nav-pills-link-padding-bottom
      $nav-pills-link-padding-x;
    line-height: $nav-pills-link-line-height;
    background-color: $nav-pills-link-hover-bgc;
    font-weight: $nav-pills-link-font-weight;
    color: $nav-pills-link-color;
    margin: $nav-pills-margin;
  }

  .nav-link.active,
  .show > .nav-link {
    background-color: $nav-pills-link-active-bgc;
    color: $nav-pills-link-active-color;
  }
}

// Fix for keeping margins of pills
.nav-fill,
.nav-justified {
  .nav-item {
    .nav-link {
      width: auto;
    }
  }
}
